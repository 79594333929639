import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  LibFormComponent,
  PanelComponent,
} from '@maersk-global/angular-shared-library';
import { DropDownOption } from '@maersk-global/angular-shared-library/lib/models/drop-down';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  debounceTime,
  firstValueFrom,
  lastValueFrom,
  map,
  merge,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { CustomerRecoveryClaimService } from '../../../common/services/customer-recovery/customer-recovery-claim.service';
import { GcssBookingPartyDto } from '../../../common/models/gcssBookingPartyDto';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { CommonService } from '../../../common/services/customer-recovery/common.service';
import { CustomerInfoDto } from '../../../common/models/customerInfoDto';
import { LiabilityLetterComponent } from '../liability-letter/liability-letter.component';
import { LiablePartyDto } from '../../../common/models/liabilePartyDto';
import { SendMailRequest } from '../../../common/models/sendMailRequest';
import { ItemType } from '../../../common/models/itemType';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { Components } from '../../../common/constants/temporary-constant';
import { SharedDataService } from '../../../shared-data-service';

const CONSTANTS = {
  LIABLE_PARTY_TYPE: 'liablePartyType',
  LIABLE_PARTY_NAME: 'liablePartyName',
  FACT_CODE: 'factCode',
  SCV_CODE: 'scvCode',
  OTHERS: '-1',
  CONTACT_EMAIL: 'primaryContactEmail',
  CONTACT_NAME: 'contactName',
  PLACE_OF_DELIVERY: 'placeOfDelivery',
  PLACE_OF_RECEIPT: 'placeOfReceipt',
};

@Component({
  selector: 'liable-party-details',
  standalone: true,
  imports: [
    CommonModule,
    LibFormComponent,
    PanelComponent,
    LiabilityLetterComponent,
  ],
  templateUrl: './liable-party-details.component.html',
  styleUrl: './liable-party-details.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LiablePartyDetailsComponent {
  @Input() item!: TemplateModel;
  @Output() onLiabilityPartySelected = new EventEmitter<
    SendMailRequest | undefined
  >();

  loggedUserEmail: string = sessionStorage.getItem('email') ?? '';
  userId: string = sessionStorage.getItem('userId') || '';
  apiVersion: string = '1.0';
  hasNewPartyTypeSelectedByUser: boolean = false;
  constructor(
    private _customerRecoveryService: CustomerRecoveryClaimService,
    private _commonService: CommonService,
    private _sharedRecoveryCaseService: SharedRecoveryCaseService,
    private _sharedDataService: SharedDataService
  ) {}

  /**
   * Behavior subject to hold the latest liability party type selected.
   */
  currentSelectedLiabilityPartyType$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  /**
   * Behavior subject to hold the latest liability party name selected. This value is dependent of `currentSelectedLiabilityPartyType$$`
   */
  currentSelectedLiabilityPartyName$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  /**
   * Behavior subject to hold the latest fact code entered by the user.
   */
  currentEnteredFactCode$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  /**
   * Behavior subject to hold the latest scv code entered by the user.
   */
  currentEnteredSCVCode$$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  /**
   * This observable holds the saved liability party details of this case on server.
   * If party details are saved already, we will pre-populated the saved values as current selected values.
   */
  savedLiablePartyData$ = this._sharedRecoveryCaseService.LiablePartyData$.pipe(
    map((data) => data ?? null),
    tap((data) => {
      if (!!data?.gcssBookingParty?.liablePartyTypeId)
        this.currentSelectedLiabilityPartyType$$.next(
          data.gcssBookingParty.liablePartyTypeId
        );
      if (!!data?.gcssBookingParty?.liablePartyName)
        this.currentSelectedLiabilityPartyName$$.next(
          data.gcssBookingParty.liablePartyName
        );
      else {
        if (!!data?.gcssBookingParty?.factCustomerCode)
          this.currentEnteredFactCode$$.next(
            data.gcssBookingParty.factCustomerCode
          );
        else if (!!data?.gcssBookingParty?.scvCustomerCode)
          this.currentEnteredSCVCode$$.next(
            data.gcssBookingParty.scvCustomerCode
          );
      }
    }),
    take(1),
    shareReplay(1)
  );

  /**
   * Observable holds the party types for the current case.
   */
  liabilityPartyTypes$: Observable<GcssBookingPartyDto[] | undefined> =
    this._sharedRecoveryCaseService.recoveryCaseData$.pipe(
      tap((customerRecoveryData) =>
        this.disableAllFieldsIfCaseIsClosed(customerRecoveryData)
      ),
      switchMap((customerRecoveryData) =>
        this._customerRecoveryService
          .customerRecoveryClaimsCaseIdPartiesGet(
            customerRecoveryData?.recoveryCaseNumber ?? '',
            this.apiVersion
          )
          .pipe(
            map((response) => response?.data),
            catchError((_) => of(undefined))
          )
      ),
      shareReplay(1)
    );

  /**
   * Observable holds the party types as dropdown items for the current case.
   */
  liabilityPartyTypesAsDropDownItems$: Observable<
    DropDownOption[] | undefined
  > = this.liabilityPartyTypes$.pipe(
    map((parties) => {
      const dropDownItems =
        parties
          ?.map((party) => {
            return {
              label: party?.liablePartyType,
              value: party?.liablePartyTypeId,
            } as DropDownOption;
          })
          ?.filter(
            (value: DropDownOption, index: number, self: DropDownOption[]) =>
              self.findIndex((m) => m.label === value.label) === index
          ) ?? [];
      dropDownItems?.push({
        label: 'Others',
        value: '-1',
      });
      return dropDownItems;
    }),
    shareReplay(1)
  );

  /**
   * Observable holds the party names as dropdown items for the current case. This dropdown list is dependent of `currentSelectedLiabilityPartyType$$`.
   */
  liabilityPartiesAsDropDownItems$: Observable<DropDownOption[] | undefined> =
    combineLatest([
      this.currentSelectedLiabilityPartyType$$.asObservable(),
      this.liabilityPartyTypes$,
    ]).pipe(
      map(([selectedLiabilityPartyType, liabilityPartyTypes]) => {
        if (!!selectedLiabilityPartyType)
          liabilityPartyTypes = liabilityPartyTypes?.filter(
            (liabilityPartyType) =>
              liabilityPartyType.liablePartyTypeId ===
              selectedLiabilityPartyType
          );
        return liabilityPartyTypes
          ?.map((liabilityPartyType) => {
            return {
              label: liabilityPartyType?.liablePartyName,
              value: liabilityPartyType?.factCustomerCode,
            } as DropDownOption;
          })
          ?.filter(
            (value: DropDownOption, index: number, self: DropDownOption[]) =>
              self.findIndex((m) => m.label === value.label) === index
          );
      })
    );

  /**
   * This observable always holds the latest customer details for the selected customer (either via liability party name dropdown or fact code text input).
   * It emits null if no value is selected.
   * */
  customerDetails$: Observable<CustomerInfoDto | null> = merge(
    this.currentSelectedLiabilityPartyName$$.asObservable(),
    this.currentEnteredFactCode$$.asObservable(),
    this.currentEnteredSCVCode$$.asObservable()
  ).pipe(
    debounceTime(500),
    switchMap((customerCode) => {
      if (!customerCode || customerCode.length < 5) return of(null);
      return this._commonService
        .commonCustomersCmdCustomerCodeGet(
          customerCode,
          this.currentEnteredSCVCode$$.value ? 'SCV' : undefined,
          this.apiVersion
        )
        .pipe(
          map((response) => response.data ?? null),
          catchError(() => of(null))
        );
    }),
    tap((customerDetails) => {
      if (customerDetails)
        this._sharedRecoveryCaseService.updateCustomerData(customerDetails);
    }),
    shareReplay(1)
  );

  /**
   * Observable emits the modified template model for the form fields.
   */
  templateModelForm$: Observable<TemplateModel[] | undefined> =
    this.customerDetails$.pipe(
      switchMap(async (customerDetails) => {
        await this.populateLiabilityPartyTypeDropDown();
        await this.populateLiabilityPartyDropdownOrFactCodeSCVCodeTextBox(
          customerDetails
        );
        await this.populateDependentFieldsOfAllSections(customerDetails);
        return this.item.items;
      })
    );

  /**
   * This method populates the first root element i.e. liability party type dropdown.
   * It sets the initial value if liability party details is already saved.
   * @returns void
   */
  async populateLiabilityPartyTypeDropDown() {
    const currentPartyType = this.currentSelectedLiabilityPartyType$$.value;
    if (!!currentPartyType) return;
    const liablePartyData = await firstValueFrom(this.savedLiablePartyData$);

    const liablePartySelectionSection = this.item.items?.[0];
    liablePartySelectionSection?.items?.forEach(async (item) => {
      if (item.name === CONSTANTS.LIABLE_PARTY_TYPE) {
        item.options = await firstValueFrom(
          this.liabilityPartyTypesAsDropDownItems$
        );
        item.value =
          item.options?.length === 1 && item.options[0].value === '-1'
            ? '-1'
            : currentPartyType ||
              liablePartyData?.gcssBookingParty?.liablePartyTypeId ||
              '';
        item.onValueChanged = (value) => {
          // If user has not selected new party type or value is null, do not emit the dependent selections as empty.
          if (
            !value ||
            (value === liablePartyData?.gcssBookingParty?.liablePartyTypeId &&
              !this.hasNewPartyTypeSelectedByUser)
          ) {
            return;
          }
          this.hasNewPartyTypeSelectedByUser = true;
          // We will make the other dependent selections empty when this dropdown value changes.
          this.currentSelectedLiabilityPartyType$$.next(value);
          this.currentSelectedLiabilityPartyName$$.next('');
          this.currentEnteredFactCode$$.next('');
          this.currentEnteredSCVCode$$.next('');
          this.onLiabilityPartySelected.emit();
        };
      }
      // Lets make all other fields of liablePartySelectionSection hidden.
      if (
        [
          CONSTANTS.LIABLE_PARTY_NAME,
          CONSTANTS.SCV_CODE,
          CONSTANTS.FACT_CODE,
          CONSTANTS.CONTACT_EMAIL,
        ].includes(item.name)
      ) {
        item.value = '';
        item.hidden = true;
      }
    });
  }

  /**
   * This method will populate liability party names dropdown or fact code text input based on liability party type selection.
   */
  async populateLiabilityPartyDropdownOrFactCodeSCVCodeTextBox(
    customerDetails: CustomerInfoDto | null
  ) {
    const currentPartyType = this.currentSelectedLiabilityPartyType$$.value;
    const currentFactCode = this.currentEnteredFactCode$$.value;
    const currentSCVCode = this.currentEnteredSCVCode$$.value;
    const liablePartySelectionSection = this.item.items?.[0];
    liablePartySelectionSection?.items?.forEach(async (item) => {
      if (item.name === CONSTANTS.LIABLE_PARTY_NAME) {
        item.hidden = !(
          !!currentPartyType && currentPartyType !== CONSTANTS.OTHERS
        );
        item.options = await firstValueFrom(
          this.liabilityPartiesAsDropDownItems$
        );
        item.value = this.currentSelectedLiabilityPartyName$$.value || '';
        item.onValueChanged = (value) => {
          if (!value) return;
          this.currentSelectedLiabilityPartyName$$.next(value);
          this.onLiabilityPartySelected.emit();
        };
      }

      if (item.name === CONSTANTS.FACT_CODE) {
        item.hidden = !(
          !!currentPartyType && currentPartyType === CONSTANTS.OTHERS
        );
        item.value = currentFactCode || '';
        item.onValueChanged = (value) => {
          if (value === customerDetails?.factCode) return;
          this.currentEnteredFactCode$$.next(value);
          this.onLiabilityPartySelected.emit();
        };
      }

      if (item.name === CONSTANTS.SCV_CODE) {
        item.hidden = !(
          !!currentPartyType && currentPartyType === CONSTANTS.OTHERS
        );
        item.value = currentSCVCode || '';
        item.onValueChanged = (value) => {
          if (value === customerDetails?.scvCode) return;
          this.currentEnteredSCVCode$$.next(value);
          this.onLiabilityPartySelected.emit();
        };
      }
    });
  }

  /**
   * This method populates all the other form fields which were dependent on liability party type and liability party name.
   * @param customerDetails selected customer details
   * @returns
   */
  async populateDependentFieldsOfAllSections(
    customerDetails: CustomerInfoDto | null
  ) {
    if (!customerDetails) {
      this.hideDependentFieldsOfAllSections();
      return;
    }

    const masterContactKeyValue = customerDetails.contacts?.filter(
      (contact) => contact.isMasterContact
    )[0] as {
      [key: string]: unknown;
    };
    const customerKeyValue = customerDetails as {
      [key: string]: unknown;
    };

    await this.populateDependentFieldsOfLiabilitySelectionSection(
      masterContactKeyValue,
      customerKeyValue
    );
    this.populateDependentFieldsOfCustomerDetailsSection(
      masterContactKeyValue,
      customerKeyValue
    );
    await this.populateDependentFieldsOfPartyDetailsSection(customerKeyValue);

    await this.emitLiabilityPartySelection(
      masterContactKeyValue,
      customerDetails
    );
  }

  /**
   * Method hides all dependent fields from all the sections if no customer details available.
   */
  hideDependentFieldsOfAllSections() {
    const liablePartySelectionSection = this.item.items?.[0];
    liablePartySelectionSection?.items?.forEach((item) => {
      if (CONSTANTS.CONTACT_EMAIL === item.name) item.hidden = true;
    });
    const customerDetailsSection = this.item.items?.[1];
    if (customerDetailsSection) customerDetailsSection.hidden = true;
    const partyDetailsSection = this.item.items?.[2];
    if (partyDetailsSection) partyDetailsSection.hidden = true;
  }

  /**
   * This method populates the first section i.e. liablePartySelectionSection
   * @param masterContactKeyValue master contact fields as key value
   * @param customerKeyValue customer details as key value
   */
  async populateDependentFieldsOfLiabilitySelectionSection(
    masterContactKeyValue: {
      [key: string]: unknown;
    },
    customerKeyValue: { [key: string]: unknown }
  ) {
    const liablePartySelectionSection = this.item.items?.[0];
    const party = await firstValueFrom(this.savedLiablePartyData$);
    liablePartySelectionSection?.items?.forEach((item) => {
      if (item.name === CONSTANTS.CONTACT_EMAIL) {
        item.hidden = false;
        item.value = !!party
          ? party.customEmailAddress
          : masterContactKeyValue[item.name];
      }
      if (item.name === CONSTANTS.FACT_CODE) {
        item.value = customerKeyValue[item.name];
      }

      if (item.name === CONSTANTS.SCV_CODE) {
        item.value = customerKeyValue[item.name];
      }
    });
  }

  /**
   * This method populates the second section i.e. customerDetailsSection
   * @param masterContactKeyValue master contact fields as key value
   * @param customerKeyValue customer details as key value
   */
  populateDependentFieldsOfCustomerDetailsSection(
    masterContactKeyValue: { [key: string]: unknown },
    customerKeyValue: { [key: string]: unknown }
  ) {
    const customerDetailsSection = this.item.items?.[1];
    if (customerDetailsSection) customerDetailsSection.hidden = false;
    customerDetailsSection?.items?.forEach((item) => {
      if (item.name === CONSTANTS.CONTACT_NAME)
        item.value = masterContactKeyValue[item.name];
      else item.value = customerKeyValue[item.name];
    });
  }

  /**
   * This method populates the third section i.e. partyDetailsSection
   * @param customerKeyValue customer details as key value
   */
  async populateDependentFieldsOfPartyDetailsSection(customerKeyValue: {
    [key: string]: unknown;
  }) {
    const partyDetailsSection = this.item.items?.[2];
    const party = await firstValueFrom(this.savedLiablePartyData$);
    const bookingDetails = await firstValueFrom(
      this._sharedRecoveryCaseService.bookingCargoDetails$
    );
    const partyKeyValue = party as {
      [key: string]: unknown;
    };
    if (partyDetailsSection) partyDetailsSection.hidden = false;
    partyDetailsSection?.items?.forEach((item) => {
      if (
        item.name === CONSTANTS.PLACE_OF_DELIVERY ||
        item.name === CONSTANTS.PLACE_OF_RECEIPT
      ) {
        item.OptionAsync = (value) =>
          this._sharedDataService.onGeoLocationTypeAheadSearched(value);
        item.value = !!party
          ? partyKeyValue[item.name]
          : bookingDetails
            ? item.name === CONSTANTS.PLACE_OF_DELIVERY
              ? bookingDetails.podSiteId
              : bookingDetails.polSiteId
            : '';
      } else item.value = customerKeyValue[item.name];
    });
  }

  /**
   * This method disables the form fields if this case is found closed.
   * @param customerRecoveryData recovery case data
   */
  disableAllFieldsIfCaseIsClosed(
    customerRecoveryData: CustomerRecoveryCaseDto | undefined
  ) {
    this.item?.items?.forEach(async (section: TemplateModel) => {
      section.items?.forEach(async (subSection: TemplateModel) => {
        subSection.disabled =
          customerRecoveryData?.recoveryStatusId == 6 ||
          customerRecoveryData?.recoveryStatusId == 7;
      });
    });
  }

  /**
   * This method emits valid liability party selection which will be used in liability letter component.
   * @param masterContactKeyValue  master contact fields as key value
   * @param customerDetails customer details
   */
  async emitLiabilityPartySelection(
    masterContactKeyValue: { [key: string]: unknown },
    customerDetails: CustomerInfoDto
  ) {
    const party = await firstValueFrom(this.savedLiablePartyData$);
    const caseRecoveryDetails = await firstValueFrom(
      this._sharedRecoveryCaseService.recoveryCaseData$
    );
    const liabilityEmailLetterContent = {
      to: masterContactKeyValue[CONSTANTS.CONTACT_EMAIL] as string,
      from: this.loggedUserEmail,
      body: '',
      caseNumber: caseRecoveryDetails?.recoveryCaseNumber ?? '',
      emailType: ItemType.LiabilityLetter,
      subject: '',
      liabilityLetter: {
        createdBy: this.userId,
        createdDate: new Date(),
        liabilityAmount: 0,
        liabilityPartyName:
          customerDetails.companyName ??
          party?.gcssBookingParty?.liablePartyName ??
          '',
        recoveryCaseId: 0,
      },
    };
    this.onLiabilityPartySelected.emit(liabilityEmailLetterContent);
  }

  /**
   *
   * @returns Method to save the liability party data to the server.
   */
  async saveLiabilityParties() {
    let liablePartyRequest: LiablePartyDto = {};

    const partyDetailsSection = this.item?.items?.[2];
    const liabilityPartySelectionSection = this.item?.items?.[0];
    const liabilityPartySection = this.item?.items?.[1];
    partyDetailsSection?.items?.forEach((item) => {
      liablePartyRequest = { ...liablePartyRequest, [item.name]: item.value };
    });

    liablePartyRequest.customEmailAddress =
      liabilityPartySelectionSection?.items?.filter(
        (i) => i.name == 'primaryContactEmail'
      )[0].value;
    const liabilityPartyTypeId = liabilityPartySelectionSection?.items?.filter(
      (i) => i.name == 'liablePartyType'
    )[0].value;
    liablePartyRequest.gcssBookingParty = {
      scvCustomerCode: liabilityPartySection?.items?.filter(
        (i) => i.name == 'scvCode'
      )[0].value,
      factCustomerCode: liabilityPartySection?.items?.filter(
        (i) => i.name == 'factCode'
      )[0].value,
      liablePartyName: liabilityPartySelectionSection?.items?.filter(
        (i) => i.name == 'liablePartyName'
      )[0].value,

      liablePartyType: liabilityPartySelectionSection?.items
        ?.filter((i) => i.name == 'liablePartyType')[0]
        .options?.filter((i) => i.value == liabilityPartyTypeId)[0].label,
      liablePartyTypeId: liabilityPartyTypeId,
    };

    liablePartyRequest.customBookingParty = {
      scvCustomerCode: liablePartyRequest.gcssBookingParty.scvCustomerCode,
      factCustomerCode: liablePartyRequest.gcssBookingParty.factCustomerCode,
    };

    const caseRecoveryDetails = await firstValueFrom(
      this._sharedRecoveryCaseService.recoveryCaseData$
    );
    const saveParties = await lastValueFrom(
      this._customerRecoveryService.customerRecoveryClaimsCaseNumberLiablePartyPost(
        caseRecoveryDetails?.recoveryCaseNumber ?? '',
        liablePartyRequest
      )
    );

    return saveParties;
  }

  /**
   * The event callback executed by `lib-form` control when the form is valid.
   * @param isValid validity of the form
   */
  formValidityChanged(isValid: boolean) {
    this._sharedRecoveryCaseService.updateFormValidationState({
      component: Components.LiablePartyDetailsComponent,
      state: isValid,
    });
  }
}
