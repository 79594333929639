<div class="container">
  <div class="header">
    <span class="page-title">
      <h3>User Management</h3>
    </span>
  </div>
  <div>
    <app-grid
      [schema]="usersGridColumnSchema"
      [data]="userGridData$ | async"
      [showPagination]="false"></app-grid>
  </div>
</div>
