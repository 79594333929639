import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UserService } from '../../common/services/user/user.service';
import {
  catchError,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import {
  ALIGN,
  AuthenticationService,
  COLUMN_CLICK_TYPE,
  GridCellData,
  GridColumnSchema,
  GridRowData,
} from '@maersk-global/angular-shared-library';
import * as userGridSchema from './user-grid-schema.json';
import { User } from '../../common/models/user';
import { GridComponent } from '@maersk-global/angular-shared-library';

@Component({
  selector: 'user-management',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, GridComponent],
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UserManagementComponent implements OnInit {
  usersGridColumnSchema: GridColumnSchema[] | null = [];
  userGridSchema = userGridSchema;

  users$: Observable<User[] | null> = this.authenticationService.user$.pipe(
    switchMap(() =>
      this.userServices.userGet().pipe(catchError(() => of(null)))
    ),
    map((response) => (response?.data ? response.data : null)),
    shareReplay(1)
  );

  userGridData$: Observable<GridRowData[] | null> | undefined =
    this.users$.pipe(
      map((users) =>
        users ? users.map((user) => this.generateGridDataFromUsers(user)) : null
      )
    );

  /**
   * Constructor
   */
  constructor(
    private userServices: UserService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.loadUsersGridSchema();
  }

  loadUsersGridSchema() {
    this.usersGridColumnSchema = this.userGridSchema.items.map((y: any) => {
      const column = {
        column: y.name,
        displayName: y.label,
        align: ALIGN.LEFT,
        hidden: y.hidden,
        sequence: y.sequence,
        columnType: y.valueType?.toUpperCase(),
        disableSort: true,
        isClickable: y.isClickable,
        columClickType: y.columClickType as COLUMN_CLICK_TYPE,
        onClick: this.onUserClick.bind(this) as unknown,
      } as GridColumnSchema;
      return column;
    });
  }

  onUserClick(cellData: GridCellData) {
    // To be implemented on click of user to edit user
  }

  private generateGridDataFromUsers(user: User): GridRowData {
    const customerRecoveryKeyValue = user as unknown as {
      [key: string]: unknown;
    };
    const gridRowObject: { [key: string]: GridCellData } = {};
    Object.keys(user).map((key) => {
      gridRowObject[key] = {
        value: customerRecoveryKeyValue[key],
      } as GridCellData;
    });
    gridRowObject['roleName'] = {
      value: user.userRoles?.find((x) => x.role.isActive)?.role.roleName,
    } as GridCellData;
    gridRowObject['name'] = {
      value: user.firstName + ' ' + user.lastName,
    } as GridCellData;
    gridRowObject['isExternal'] = {
      value: user.isExternal ? 'Y' : 'N',
    } as GridCellData;
    gridRowObject['isActive'] = {
      value: user.isActive ? 'Active' : 'Inactive',
    } as GridCellData;
    return {
      row: gridRowObject,
      hideRowSelector: true,
    } as GridRowData;
  }
}
