import { Injectable } from '@angular/core';
import { Observable, filter, map, merge, tap } from 'rxjs';
import { LiabilityLetterDto } from './common/models/liabilityLetterDto';
import { InvoicingStatus } from './common/models/invoicingStatus';
import { CustomerInfoDto } from './common/models/customerInfoDto';
import { LiablePartyDto } from './common/models/liabilePartyDto';
import { gcssBookingInfo } from './common/models/gcssBookingInfo';
import { CaseDamageDetailDto } from './common/models/caseDamageDetailDto';
import { CaseInvoiceDetailDto } from './common/models/caseInvoiceDetailDto';
import { ContainerMoveDto } from './common/models/containerMoveDto';
import { SharedCustomerRecoveryCaseService } from './components/customer-recovery/shared-customer-recovery-case.service';
import { CustomerRecoveryCaseDto } from './common/models/customerRecoveryCaseDto';
import { FormValidation } from './common/models/formValidation';
import { SharedVendorRecoveryCaseService } from './components/vendor-recovery/shared-vendor-recovery-case.service';
import { RecoveryCase } from './common/models/recoveryCase';
import { VendorRecoveryCase } from './common/models/vendorRecoveryCase';
import { ClaimReferenceDocumentDto } from './common/models/claimReferenceDocumentDto';
import { Inspection } from './common/models/inspection';
import { TemplateModel } from '@maersk-global/angular-shared-library';
import { InspectionDetailsDTO } from './common/models/inspectionDetailsDTO';

export interface ISharedRecoveryCaseService {
  reloadRecoveryCaseData(): void;
  reloadDamageDetails(): void;
  reloadLiabilityPartyDetails(): void;
  reloadLiabilityLetters(): void;
  reloadInvoices(): void;
  updateLiabilityDetailsVisibility(value: boolean): void;
  updateIssueInvoiceVisibility(value: boolean): void;
  updateEirImageLastFetchOn(date: Date | undefined): void;
  updateCustomerData(data: CustomerInfoDto): void;
  updateTabSelected(tabIndex: number): void;
  updateContainerDetails(
    containerDetails: ContainerMoveDto | null | undefined
  ): void;
  updateCurrentStageId(stageId: number): void;
  updateFormValidationState(state: FormValidation): void;
  saveDamageDetails(damages: TemplateModel[], caseId: number): Promise<boolean>;
  caseDocumentGet(caseId: number): Observable<ClaimReferenceDocumentDto | null>;
  caseDocumentPost(
    imagesAssignedToCases: ClaimReferenceDocumentDto
  ): Observable<ClaimReferenceDocumentDto | null>;
  caseDocumentDelete(documentId: number): void;
  inspectionsGet(
    caseNumber: string,
    containerNumber: string | undefined,
    incidentDate: Date | null,
    workOrderDate: Date | null
  ): Observable<Array<Inspection> | null>;
  saveImageAvailability(
    inspectionInfo: InspectionDetailsDTO,
    caseNumber: string
  ): Observable<boolean>;
}

@Injectable({
  providedIn: 'root',
})
export class SharedRecoveryCaseService {
  caseContext!: ISharedRecoveryCaseService;

  constructor(
    private _customerRecoverySharedService: SharedCustomerRecoveryCaseService,
    private _vendorRecoverySharedService: SharedVendorRecoveryCaseService
  ) {}

  recoveryCaseData$: Observable<RecoveryCase> = merge(
    this._customerRecoverySharedService.customerRecoveryCaseData$.pipe(
      filter((customerRecovery) => !!customerRecovery),
      tap(() => {
        this.caseContext = this._customerRecoverySharedService;
      }),
      map((customerRecovery) => {
        return this.buildRecoveryCaseModelForCustomer(
          customerRecovery as CustomerRecoveryCaseDto
        );
      })
    ),
    this._vendorRecoverySharedService.vendorRecoveryCaseData$.pipe(
      filter((vendorRecovery) => !!vendorRecovery),
      tap(() => {
        this.caseContext = this._vendorRecoverySharedService;
      }),
      map((vendorRecovery) => {
        return this.buildRecoveryCaseModelForVendor(
          vendorRecovery as VendorRecoveryCase
        );
      })
    )
  );

  disableForm$: Observable<boolean> = merge(
    this._customerRecoverySharedService.disableForm$.pipe(
      filter(
        () => this.caseContext instanceof SharedCustomerRecoveryCaseService
      )
    ),
    this._vendorRecoverySharedService.disableForm$.pipe(
      filter(() => this.caseContext instanceof SharedVendorRecoveryCaseService)
    )
  );

  buildRecoveryCaseModelForCustomer(
    customer: CustomerRecoveryCaseDto
  ): RecoveryCase {
    return {
      caseId: customer.caseId,
      recoveryCaseNumber: customer.recoveryCaseNumber,
      workOrderNumber: customer.workOrderNumber,
      containerNumber: customer.containerNumber,
      containerProdYear: customer.containerProdYear,
      gateInDate: customer.gateInDate,
      bookingNumber: customer.bookingNumber,
      bolNumber: customer.bolNumber,
      shopCode: customer.shopCode,
      shopCountryId: customer.shopCountryId,
      shopCountryCode: customer.shopCountryCode,
      shopCountryName: customer.shopCountryName,
      podCountryId: customer.podCountryId,
      podCountryCode: customer.podCountryCode,
      podCountryName: customer.podCountryName,
      podCountryClusterCode: customer.podCountryClusterCode,
      podCountryClusterName: customer.podCountryClusterName,
      workOrderCostUSD: customer.workOrderCostUSD,
      recoverableCostUSD: customer.recoverableCostUSD,
      withinCoverageCostUSD: customer.withinCoverageCostUSD,
      aboveCoverageCostUSD: customer.aboveCoverageCostUSD,
      recoveryStatusId: customer.recoveryStatusId,
      workorderStatusCode: customer.workorderStatusCode,
      operatorCode: customer.operatorCode,
      placeOfIncident: customer.placeOfIncident,
      dateOfIncident: customer.dateOfIncident,
      aboveCoverageBand: customer.aboveCoverageBand,
      importReturn: customer.importReturn,
      assignedToUID: customer.assignedToUID,
      cpPurchased: customer.cpPurchased,
      priority: customer.priority,
      isCostChanged: customer.isCostChanged,
      groupId: customer.groupId,
      comments: customer.comments,
      cancellationReason: customer.cancellationReason,
      hasLinkedCases: customer.hasLinkedCases,
      gateInLapseInDays: customer.gateInLapseInDays,
      workOrderDate: customer.workOrderDate,
      invoiceNumber: customer.invoiceNumber,
      workFlowVersion: customer.workFlowVersion,
      templateVersion: customer.templateVersion,
      stopUpgrade: customer.stopUpgrade,
      woMode: customer.woMode,
      podLocalCurrency: customer.podLocalCurrency,
      equipmentSubType: customer.equipmentSubType,
      containerSize: customer.containerSize,
      cause: customer.cause,
      equipmentOwnershipType: customer.equipmentOwnershipType,
      completionDate: customer.completionDate,
      vendorReference: customer.vendorReference,
      createdBy: customer.createdBy,
      createdDate: customer.createdDate,
      updatedBy: customer.updatedBy,
      updatedDate: customer.updatedDate,
      locationId: customer.locationId,
      containerEmptyFull: customer.containerEmptyFull,
      siteName: customer.siteName,
      containerCurrentLocation: customer.containerCurrentLocation,
      eirImageLastCheckedOn: customer.eirImageLastCheckedOn,
      caseCurrency: customer.caseCurrency,
      exchangeRateUSDCaseCurrency: customer.exchangeRateUSDCaseCurrency,
      workOrderCostCaseCurrency: customer.workOrderCostCaseCurrency,
      withinCoverageCostCaseCurrency: customer.withinCoverageCostCaseCurrency,
      recoverableCostCaseCurrency: customer.recoverableCostCaseCurrency,
      aboveCoverageCostCaseCurrency: customer.aboveCoverageCostCaseCurrency,
      handlingDamageCostUSD: customer.handlingDamageCostUSD,
      workFlowStage: customer.workFlowStage,
      cpCoverageAmount: customer.cpCoverageAmount,
      cpCoverageCurrency: customer.cpCoverageCurrency,
      cpMasterContainerSize: customer.cpMasterContainerSize,
      isCpActiveCountry: customer.isCpActiveCountry,
      podRegionCode: customer.podRegionCode,
      podRegionName: customer.podRegionName,
      recoveryCaseType: 'Customer',
    } as RecoveryCase;
  }

  buildRecoveryCaseModelForVendor(vendor: VendorRecoveryCase): RecoveryCase {
    return {
      caseId: vendor.id,
      groupId: vendor.groupId,
      equipmentNumber: vendor.equipmentNumber,
      equipmentType: vendor.equipmentType,
      equipmentSize: vendor.equipmentSize,
      equipmentManufacturingYear: vendor.equipmentManufacturingYear,
      equipmentOwnershipType: vendor.equipmentOwnershipType,
      recoveryCaseType: vendor.recoveryCaseType,
      responsibleCountryCode: vendor.responsibleCountryCode,
      responsibleCountryClusterCode: vendor.responsibleCountryClusterCode,
      responsibleRegionCode: vendor.responsibleRegionCode,
      responsibleCountryName: vendor.responsibleCountryName,
      responsibleCountryClusterName: vendor.responsibleCountryClusterName,
      responsibleRegionName: vendor.responsibleRegionName,
      recoverableCostInUsd: vendor.recoverableCostInUsd,
      aboveCoverageCostInUsd: vendor.aboveCoverageCostInUsd,
      caseStatus: vendor.caseStatus,
      bolNumber: vendor.bolNumber,
      bookingNumber: vendor.bookingNumber,
      invoiceNumber: vendor.invoiceNumber,
      createSource: vendor.createSource,
      assignedTo: vendor.assignedTo,
      createTimestamp: vendor.createdTimestamp,
      createUserId: vendor.createdByUserId,
      workOrderNumber: vendor.workOrderNumber,
      shopCode: vendor.shopCode,
      shopCountryCode: vendor.shopCountryCode,
      shopCountryName: vendor.shopCountryName,
      recoveryStatusId: this._vendorRecoverySharedService.getCaseStatusIDByName(
        vendor.caseStatus ?? 'New'
      ),
      workFlowStage: 'New',
    } as RecoveryCase;
  }

  customerData$: Observable<CustomerInfoDto> = merge(
    this._customerRecoverySharedService.customerRecoveryCustomerData$
  );

  currentStageId$: Observable<number> = merge(
    this._customerRecoverySharedService.currentStageId$
  );

  workOrderDate$: Observable<Date | null | undefined> = merge(
    this._vendorRecoverySharedService.workOrderDate$
  );

  incidentDate$: Observable<Date | null | undefined> = merge(
    this._customerRecoverySharedService.incidentDate$
  );

  eirImageLastFetchDate$: Observable<Date | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryEirImageLastFetchDate$,
    this._vendorRecoverySharedService.vendorRecoveryEirImageLastFetchDate$
  );

  containerDetails$: Observable<ContainerMoveDto | null | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryContainerMovesDetails$
  );

  currentTabIndex$: Observable<number | 0> = merge(
    this._customerRecoverySharedService.currentCustomerRecoveryTabIndex$,
    this._vendorRecoverySharedService.currentVendorRecoveryTabIndex$
  );

  LiablePartyData$: Observable<LiablePartyDto | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryLiablePartyData$
  );

  liabilityLetters$: Observable<Array<LiabilityLetterDto> | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryLiabilityLetters$
  );

  shouldShowLiabilityDetails$: Observable<boolean> = merge(
    this._customerRecoverySharedService
      .shouldShowCustomerRecoveryLiabilityDetails$
  );

  invoices$: Observable<Array<CaseInvoiceDetailDto> | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryInvoices$
  );

  shouldShowIssueInvoiceScreen$: Observable<boolean> = merge(
    this._customerRecoverySharedService
      .shouldShowCustomerRecoveryIssueInvoiceScreen$
  );

  invoiceCreationPermission$: Observable<
    InvoicingStatus & { displayMessage: string }
  > = merge(
    this._customerRecoverySharedService
      .customerRecoveryInvoiceCreationPermission$
  );

  bookingCargoDetails$: Observable<gcssBookingInfo | undefined> = merge(
    this._customerRecoverySharedService.customerRecoveryBookingCargoDetails$
  );

  damageDetails$: Observable<CaseDamageDetailDto[] | undefined> = merge(
    this._customerRecoverySharedService.damageDetails$,
    this._vendorRecoverySharedService.damageDetails$
  );

  saveDamageDetails = (damages: TemplateModel[], caseId: number) =>
    this.caseContext?.saveDamageDetails(damages, caseId);

  reloadRecoveryCaseData = () => this.caseContext?.reloadRecoveryCaseData();

  reloadDamageDetails = () => this.caseContext?.reloadDamageDetails();

  reloadLiabilityPartyDetails = () =>
    this.caseContext?.reloadLiabilityPartyDetails();

  reloadLiabilityLetters = () => this.caseContext?.reloadLiabilityLetters();

  reloadInvoices = () => this.caseContext?.reloadInvoices();

  updateLiabilityDetailsVisibility = (value: boolean) =>
    this.caseContext?.updateLiabilityDetailsVisibility(value);

  updateIssueInvoiceVisibility = (value: boolean) =>
    this.caseContext?.updateIssueInvoiceVisibility(value);

  updateEirImageLastFetchOn = (date: Date | undefined) =>
    this.caseContext?.updateEirImageLastFetchOn(date);

  updateCustomerData = (data: CustomerInfoDto) =>
    this.caseContext?.updateCustomerData(data);

  updateTabSelected = (tabIndex: number) =>
    this.caseContext?.updateTabSelected(tabIndex);

  updateContainerDetails = (
    containerDetails: ContainerMoveDto | null | undefined
  ) => this.caseContext?.updateContainerDetails(containerDetails);

  updateCurrentStageId = (stageId: number) =>
    this.caseContext?.updateCurrentStageId(stageId);

  updateFormValidationState = (state: FormValidation) =>
    this.caseContext?.updateFormValidationState(state);

  caseDocumentGet = (caseId: number) =>
    this.caseContext?.caseDocumentGet(caseId);

  caseDocumentPost = (imagesAssignedToCases: ClaimReferenceDocumentDto) =>
    this.caseContext?.caseDocumentPost(imagesAssignedToCases);

  caseDocumentDelete = (documentId: number) =>
    this.caseContext?.caseDocumentDelete(documentId);

  inspectionsGet = (
    caseNumber: string,
    containerNumber: string | undefined,
    incidentDate: Date | null,
    workOrderDate: Date | null
  ) =>
    this.caseContext?.inspectionsGet(
      caseNumber,
      containerNumber,
      incidentDate,
      workOrderDate
    );

  saveImageAvailability = (
    inspectionInfo: InspectionDetailsDTO,
    caseNumber: string
  ) => this.caseContext?.saveImageAvailability(inspectionInfo, caseNumber);
}
