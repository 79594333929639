<div class="workflow-stepper" *ngIf="currentStageId$ | async">
  <div class="step-indicator">
    <mc-step-indicator *ngIf="workflowSteps">
      @for (step of workflowSteps; track step; let index = $index) {
        <mc-step-indicator-item
          fit="medium"
          (click)="showStage(index)"
          [label]="step.name"
          [ngClass]="step.className"
          [state]="step.state">
          <span slot="icon">
            <img
              class="step-icon"
              *ngIf="step.className === 'completed' && step.icon == 'check'"
              src="../../../../assets/images/bold-checkmark.svg"
              alt="" />
            <img
              class="step-icon"
              *ngIf="step.className === 'current' && step.icon == 'check'"
              src="../../../../assets/images/bold-checkmark-current.svg"
              alt="" />
            <img
              class="step-icon-svg"
              *ngIf="step.className === 'stage-current'"
              src="../../../../assets/images/progress-bar-circle-icon.svg"
              alt="" />
          </span>
        </mc-step-indicator-item>
      }
    </mc-step-indicator>
  </div>
  <ng-container *ngTemplateOutlet="comments"></ng-container>
</div>
<ng-template #comments>
  <div [formGroup]="commentForm">
    <mc-popover
      arrow="true"
      position="bottom-right"
      #commentPopOver
      trigger="click"
      (show)="showCommentPopover()">
      <mc-button
        slot="trigger"
        label="Comments"
        icon="comment"
        open
        appearance="neutral"></mc-button>
      <div class="content" style="height: 230px">
        <div class="pop-over">
          <mc-textarea
            [rows]="6"
            hiddenlabel
            ngDefaultControl
            formControlName="comments"
            placeholder="Enter your comments here"
            class="text-area-box"></mc-textarea>
        </div>
        <div style="display: flex; justify-content: right">
          <mc-button
            label="Cancel"
            appearance="neutral"
            style="padding-right: 10px"
            (click)="commentPopOver.hide()"></mc-button>
          <mc-button label="Save" (click)="commentSave()"></mc-button>
        </div>
      </div>
    </mc-popover>
  </div>
</ng-template>
