<form [formGroup]="newCaseForm">
  <mc-modal
    [open]="true"
    escapecloseactiondisabled
    backdropcloseactiondisabled
    dimension="large"
    fit="medium"
    (closed)="onModelClose($event)">
    <span slot="heading" class="header-body">
      <div class="header">New Case</div>
      <div class="sub-header">
        Enter the container number to view existing work orders and recovery
        cases
      </div>
    </span>

    <div class="create-case-wrapper">
      <ng-container *ngTemplateOutlet="containerNumberSearchBox"></ng-container>
      <ng-container *ngTemplateOutlet="createCaseWithWorkOrder"></ng-container>
    </div>

    <mc-button
      focusstartanchor
      slot="primaryAction"
      appearance="primary"
      [disabled]="
        disableCaseCreation ||
        (!!containerNumberInput.errors &&
          (containerNumberInput.touched ||
            containerNumberInput.errors['isNotAlphanumeric']))
      "
      (click)="createCase()">
      Create Case
    </mc-button>

    <mc-button
      focusendanchor
      slot="secondaryAction"
      appearance="neutral"
      variant="outlined"
      (click)="onModelClose($event)">
      Cancel
    </mc-button>
  </mc-modal>

  <ng-template #containerNumberSearchBox>
    <div class="container-search">
      <mc-input
        ngDefaultControl
        [invalid]="
          !!containerNumberInput.errors &&
          (containerNumberInput.touched ||
            containerNumberInput.errors['isNotAlphanumeric'])
        "
        label="Container Number"
        placeholder="Enter Container Number"
        [maxlength]="11"
        formControlName="containerNumberInput"></mc-input>
      <mc-button
        appearance="primary"
        [disabled]="containerNumberInput.errors"
        (click)="searchContainerNumber()">
        Search
      </mc-button>
    </div>
    <mc-error
      *ngIf="
        !!containerNumberInput.errors &&
        containerNumberInput.errors?.['isNotAlphanumeric']
      "
      invalid
      errormessage="Special characters are not allowed"></mc-error>
    <mc-error
      *ngIf="
        containerNumberInput.touched &&
        !!containerNumberInput.errors &&
        containerNumberInput.errors['containerNumberInvalid'] &&
        !containerNumberInput.errors['isNotAlphanumeric']
      "
      invalid
      errormessage="Please enter a valid container number"></mc-error>
  </ng-template>

  <ng-template #createCaseWithWorkOrder>
    <ng-container
      *ngIf="workOrderGridData$ | async as workOrderGridData; else pageLoad">
      <lib-table-skeleton-loader
        *ngIf="areWorkOrdersLoading"></lib-table-skeleton-loader>
      <ng-container *ngIf="!areWorkOrdersLoading">
        <div class="work-order-details">
          <span>Use existing work order or recovery case</span>
          <span>
            Time period : {{ searchFromDateUTC | date: 'dd MMM yyyy' }} -
            {{ searchToDateUTC | date: 'dd MMM yyyy' }}
          </span>
        </div>
        <app-no-data
          *ngIf="workOrderGridData.length === 0"
          [height]="{ size: 300, unit: 'px' }">
          No work orders found
        </app-no-data>
        <app-grid
          *ngIf="workOrderGridData.length > 0"
          [schema]="woGridSchema"
          [data]="workOrderGridData"
          [showPanel]="false"
          [showRowSelector]="true"
          [showPagination]="false"
          [rowSelectorType]="'radio'"
          [disabled]="caseCreationWithoutWorkOrder"
          (rowSelectionChange)="woSelectionChanged($event)"></app-grid>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #pageLoad>
    <app-no-data
      *ngIf="!areWorkOrdersLoading"
      [height]="{ size: 300, unit: 'px' }">
      Work orders and cases for the container will be shown here
    </app-no-data>
    <lib-table-skeleton-loader
      *ngIf="areWorkOrdersLoading"></lib-table-skeleton-loader>
  </ng-template>
</form>
