/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { CaseAndWorkOrderIEnumerableResponse } from '../../models/caseAndWorkOrderIEnumerableResponse';

@Injectable()
export class CaseAndWorkOrderService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * A response containing list of
   *
   * @param equipmentNumber
   * @param dateRangeFromDateTime
   * @param dateRangeToDateTime
   * @param aPIVersion
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public casesWorkordersGet(
    equipmentNumber: string,
    dateRangeFromDateTime?: Date,
    dateRangeToDateTime?: Date,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CaseAndWorkOrderIEnumerableResponse>;
  public casesWorkordersGet(
    equipmentNumber: string,
    dateRangeFromDateTime?: Date,
    dateRangeToDateTime?: Date,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CaseAndWorkOrderIEnumerableResponse>>;
  public casesWorkordersGet(
    equipmentNumber: string,
    dateRangeFromDateTime?: Date,
    dateRangeToDateTime?: Date,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CaseAndWorkOrderIEnumerableResponse>>;
  public casesWorkordersGet(
    equipmentNumber: string,
    dateRangeFromDateTime?: Date,
    dateRangeToDateTime?: Date,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (equipmentNumber === null || equipmentNumber === undefined) {
      throw new Error(
        'Required parameter equipmentNumber was null or undefined when calling casesWorkordersGet.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (equipmentNumber !== undefined && equipmentNumber !== null) {
      queryParameters = queryParameters.set(
        'EquipmentNumber',
        <any>equipmentNumber
      );
    }
    if (dateRangeFromDateTime !== undefined && dateRangeFromDateTime !== null) {
      queryParameters = queryParameters.set(
        'DateRange.FromDateTime',
        <any>dateRangeFromDateTime.toISOString()
      );
    }
    if (dateRangeToDateTime !== undefined && dateRangeToDateTime !== null) {
      queryParameters = queryParameters.set(
        'DateRange.ToDateTime',
        <any>dateRangeToDateTime.toISOString()
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);
    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<CaseAndWorkOrderIEnumerableResponse>(
      'get',
      `${this.basePath}/cases-workorders`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
