import {
  COLUMN_CLICK_TYPE,
  COLUMN_TYPE,
  GridColumnSchema,
} from '@maersk-global/angular-shared-library';

export default Object.freeze({
  workOrderSchema: [
    {
      column: 'workOrderNumber',
      sequence: 1,
      displayName: 'Work Order',
      columnType: COLUMN_TYPE.STRING,
      isClickable: false,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'workOrderCreatedDate',
      sequence: 2,
      displayName: 'WO Created On',
      columnType: COLUMN_TYPE.DATE,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'id',
      sequence: 3,
      displayName: 'Case ID',
      columnType: COLUMN_TYPE.STRING,
      isClickable: true,
      columClickType: COLUMN_CLICK_TYPE.LINK_CLICK,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'caseStatus',
      sequence: 4,
      displayName: 'Case Status',
      columnType: COLUMN_TYPE.STRING,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'finalTotalCostUsd',
      sequence: 5,
      displayName: 'Work Order Cost(USD)',
      columnType: COLUMN_TYPE.NUMERIC,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'finalRecoverableUsdCost',
      sequence: 6,
      displayName: 'Recoverable Cost(USD)',
      columnType: COLUMN_TYPE.NUMERIC,
      disableColSorting: true,
    } as GridColumnSchema,
    {
      column: 'groupId',
      sequence: 7,
      displayName: 'Group Id',
      columnType: COLUMN_TYPE.STRING,
      disableColSorting: true,
      hidden: true,
    } as GridColumnSchema,
  ],
});
