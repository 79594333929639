/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type RecoveryCaseStatus =
  | 'New'
  | 'DamageEstimation'
  | 'Liability'
  | 'Invoice'
  | 'Complete'
  | 'Closed'
  | 'AutoClosed'
  | 'DebitNote';

export const RecoveryCaseStatus = {
  New: 'New' as RecoveryCaseStatus,
  DamageEstimation: 'DamageEstimation' as RecoveryCaseStatus,
  Liability: 'Liability' as RecoveryCaseStatus,
  Invoice: 'Invoice' as RecoveryCaseStatus,
  Complete: 'Complete' as RecoveryCaseStatus,
  Closed: 'Closed' as RecoveryCaseStatus,
  AutoClosed: 'AutoClosed' as RecoveryCaseStatus,
  DebitNote: 'DebitNote' as RecoveryCaseStatus,
};
