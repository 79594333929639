import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  lastValueFrom,
  map,
  of,
  scan,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { CaseTypeEnum } from '../../common/constants/temporary-constant';
import { CaseDamageDetailDto } from '../../common/models/caseDamageDetailDto';
import { ContainerMoveDto } from '../../common/models/containerMoveDto';
import { CustomerRecoveryCaseDto } from '../../common/models/customerRecoveryCaseDto';
import { FormValidation } from '../../common/models/formValidation';
import { WorkFlowStage } from '../../common/models/workflowStage';
import { ISharedRecoveryCaseService } from '../../shared-recovery-case-service';
import { TemplateModel } from '@maersk-global/angular-shared-library';
import { RecoveryCaseService } from '../../common/services/recovery/recovery.service';
import { RecoveryRequest } from '../../common/models/recovery-request';
import { RecoveryCaseStatus } from '../../common/models/recoveryCaseStatus';
import { VendorRecoveryCase } from '../../common/models/vendorRecoveryCase';
import { WorkOrderLineItemDto } from '../../common/models/workOrderLineItemDto';
import { CaseDamageDetailsService } from '../../common/services/recovery/caseDamageDetails.service';
import { CaseDamageDetails } from '../../common/models/caseDamageDetails';
import { SharedDataService } from '../../shared-data-service';
import { DcrpAuthorizationService } from '../../common/services/authorization/dcrp-authorization.service';
import { ClaimReferenceDocumentDto } from '../../common/models/claimReferenceDocumentDto';
import { Inspection } from '../../common/models/inspection';
import { InspectionDetailsDTO } from '../../common/models/inspectionDetailsDTO';
import { RecoveryCaseDocumentService } from '../../common/services/recovery/caseDocument.service';
import { RecoveryInspectionService } from '../../common/services/recovery/inspection.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedVendorRecoveryCaseService
  implements ISharedRecoveryCaseService
{
  private apiVersion: string = '1.0';
  caseType: CaseTypeEnum | undefined;

  constructor(
    private _route: ActivatedRoute,
    private _recoveryClaimService: RecoveryCaseService,
    private _damageService: CaseDamageDetailsService,
    private _sharedDataService: SharedDataService,
    private _dcrpAuthorizationService: DcrpAuthorizationService,
    private _recoveryCaseDocumentService: RecoveryCaseDocumentService,
    private _recoveryInspectionService: RecoveryInspectionService
  ) {}

  private shouldShowLiabilityDetailsSubject$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private tabIndex$$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private reloadDamageDetails$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private workOrderDateChange$$: BehaviorSubject<Date | null | undefined> =
    new BehaviorSubject<Date | null | undefined>(undefined);

  private currentStageId$$: BehaviorSubject<number> =
    new BehaviorSubject<number>(1);

  private reloadVendorRecoveryData$$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private eirImageLastCheckedOn$$: BehaviorSubject<Date | undefined> =
    new BehaviorSubject<Date | undefined>(undefined);

  private formValidity$$: BehaviorSubject<FormValidation | null> =
    new BehaviorSubject<FormValidation | null>(null);

  private containerDetails$$: BehaviorSubject<
    ContainerMoveDto | null | undefined
  > = new BehaviorSubject<ContainerMoveDto | null | undefined>(undefined);

  private recoverableWorkOrderSelectedLineItems$$: BehaviorSubject<
    { [key: string]: unknown }[]
  > = new BehaviorSubject<{ [key: string]: unknown }[]>([]);

  private workOrderLineItems$$: BehaviorSubject<WorkOrderLineItemDto[]> =
    new BehaviorSubject<WorkOrderLineItemDto[]>([]);

  vendorRecoveryCaseData$: Observable<VendorRecoveryCase | undefined> =
    combineLatest([
      this._route.queryParamMap,
      this.reloadVendorRecoveryData$$.asObservable(),
    ]).pipe(
      switchMap(([params]) => {
        const caseId = params.get('caseId');
        if (!caseId) return of(undefined);
        const request = {
          caseId: parseInt(caseId ?? ''),
          equipmentNumber: params.get('equipmentNumber') as string,
          recoveryCaseType: 'Vendor',
        } as RecoveryRequest;
        return this._recoveryClaimService
          .recoveryCasesGet(request)
          .pipe(map((response) => response.data?.at(0)));
      }),
      tap((recoveryData) => {
        this.resetCaseContext();
        if (!recoveryData) return;
      }),
      shareReplay(1)
    );

  currentStageId$: Observable<number> = this.currentStageId$$.asObservable();

  workOrderDate$: Observable<Date | null | undefined> =
    this.workOrderDateChange$$.asObservable();

  vendorRecoveryEirImageLastFetchDate$: Observable<Date | undefined> =
    this.eirImageLastCheckedOn$$.asObservable();

  vendorRecoveryContainerMovesDetails$: Observable<
    ContainerMoveDto | null | undefined
  > = this.containerDetails$$.asObservable();

  currentVendorRecoveryTabIndex$: Observable<number | 0> =
    this.tabIndex$$.asObservable();

  vendorRecoverableWorkOrderLineItem$: Observable<
    { [key: string]: unknown }[]
  > = this.recoverableWorkOrderSelectedLineItems$$.asObservable();

  vendorWorkOrderLineItems$: Observable<WorkOrderLineItemDto[]> =
    this.workOrderLineItems$$.asObservable();

  enableCloseBtn$: Observable<boolean> = combineLatest([
    this.vendorRecoveryCaseData$,
    this.currentStageId$,
  ]).pipe(
    map(
      ([recoveryData, stageId]) =>
        !(
          recoveryData?.caseStatus == RecoveryCaseStatus.Closed ||
          recoveryData?.caseStatus == RecoveryCaseStatus.AutoClosed ||
          stageId == 5
        )
    )
  );

  reopenCase$: Observable<boolean> = this.vendorRecoveryCaseData$.pipe(
    map((recoveryData) => {
      return (
        recoveryData?.caseStatus == RecoveryCaseStatus.Closed ||
        recoveryData?.caseStatus == RecoveryCaseStatus.AutoClosed
      );
    })
  );

  disableForm$: Observable<boolean> = combineLatest([
    // this._dcrpAuthorizationService.isAuthorizedFor('recovery-update'),
    this.vendorRecoveryCaseData$,
    this.currentStageId$,
  ]).pipe(
    map(([recoveryData]) => {
      return (
        // !isAuthorizedForRecoveryUpdate ||
        recoveryData?.caseStatus == RecoveryCaseStatus.Closed ||
        recoveryData?.caseStatus == RecoveryCaseStatus.AutoClosed
      );
    })
  );

  /**
   * We are calculating the accumulated state of the form validity. For e.g. If out of 2 forms 1 is valid and 1 is invalid,
   * the accumulated state would be invalid.
   */
  private collectiveFormValidation$ = this.formValidity$$.asObservable().pipe(
    withLatestFrom(this.currentStageId$, this.vendorRecoveryCaseData$),
    map(([formValidation, currentStageId, vendorRecoveryData]) =>
      formValidation
        ? {
            ...formValidation,
            stage: currentStageId,
            caseId: vendorRecoveryData?.id,
          }
        : null
    ),
    scan((formValidations, currentState) => {
      if (!currentState) return [];
      const matchingState = formValidations.find(
        (state) =>
          state.component === currentState.component &&
          state.stage === currentState.stage &&
          state.caseId === currentState.caseId
      );
      if (matchingState) matchingState.state = currentState.state;
      else formValidations.push(currentState);
      return formValidations;
    }, [] as FormValidation[])
  );

  private formValidityForCurrentStage$ = combineLatest([
    this.currentStageId$,
    this.collectiveFormValidation$,
    this.vendorRecoveryCaseData$,
  ]).pipe(
    map(([currentStageId, formValidations, vendorRecoveryData]) =>
      formValidations
        .filter(
          (validations) =>
            validations.stage === currentStageId &&
            validations.caseId === vendorRecoveryData?.id
        )
        .every((validation) => validation.state)
    )
  );

  enableNextBtn$: Observable<boolean> = combineLatest([
    this.vendorRecoveryCaseData$,
    this.currentStageId$,
    this.formValidityForCurrentStage$,
  ]).pipe(
    map(([recoveryData, stageId, formValid]) => {
      let enableFlag: boolean = true;
      if (
        recoveryData?.caseStatus == RecoveryCaseStatus.Closed ||
        recoveryData?.caseStatus == RecoveryCaseStatus.AutoClosed
      )
        enableFlag = enableFlag ? false : true;
      if (stageId === 5) enableFlag = false;
      return enableFlag && formValid;
    })
  );

  hideNextBtn$: Observable<boolean> = combineLatest([
    this.currentStageId$,
  ]).pipe(
    map(([stageId]) => {
      let hiddenFlag: boolean = false;
      if (stageId === 4) hiddenFlag = true;
      return hiddenFlag;
    })
  );

  async saveDamageDetails(
    damages: TemplateModel[],
    caseId: number
  ): Promise<boolean> {
    const damageDetailRequest: CaseDamageDetails[] = [];
    const currentItems = damages;

    currentItems?.forEach((damage: TemplateModel) => {
      if (
        !damage.hidden &&
        damage.items &&
        this._sharedDataService.validateDamageLineItem(damage.items)
      )
        damageDetailRequest.push({
          damageDescription: damage.items?.filter(
            (i) => i.name == 'description'
          )[0].value,
          damageAmountInUsd: damage.items?.filter((i) => i.name == 'amount')[0]
            .value,
          materialCodeId: damage.items?.filter(
            (i) => i.name == 'materialCode'
          )[0].value,
          id: damage.items?.filter((i) => i.name == 'id')[0].value,
          caseId: caseId,
          damageAmountInCaseCurrency: damage.items?.filter(
            (i) => i.name == 'amountInCaseCurrency'
          )[0].value,
        });
    });

    const saveSuccess = await lastValueFrom(
      this._damageService
        .caseDamageDetailsDeleteAndInsertPut(damageDetailRequest)
        .pipe(map((response) => response.isSuccess ?? false))
    );
    if (saveSuccess) this.reloadDamageDetails();
    return saveSuccess;
  }

  damageDetails$: Observable<CaseDamageDetailDto[] | undefined> = combineLatest(
    [this.vendorRecoveryCaseData$, this.reloadDamageDetails$$.asObservable()]
  ).pipe(
    switchMap(([vendorRecoveryData]) => {
      if (!vendorRecoveryData || !vendorRecoveryData.id) return of(undefined);
      return this._damageService
        .caseDamageDetailsByCaseIdCaseIdGet(vendorRecoveryData?.id ?? 0)
        .pipe(
          map((response) => {
            if (!response || !response.data) return [];
            return response.data.map(
              (site) =>
                ({
                  description: site.damageDescription,
                  id: site.id,
                  materialCode: site.materialCodeId,
                  amount: site.damageAmountInUsd,
                  amountInCaseCurrency: site.damageAmountInCaseCurrency,
                  caseId: site.caseId,
                }) as CaseDamageDetailDto
            );
          }),
          tap((res) => console.log(res))
        );
    }),
    shareReplay(1)
  );

  resetCaseContext() {
    this.currentStageId$$.next(1);
    this.updateWorkOrderLineItems([]);
    this.updateRecoverableWorkOrderSelectedLineItems([]);
    this.updateContainerDetails(undefined);
    this.updateWorkOrderDate(undefined);
  }

  updateFormValidationState(state: FormValidation) {
    this.formValidity$$.next(state);
  }

  reloadDamageDetails(): void {
    this.reloadDamageDetails$$.next(true);
  }

  updateLiabilityDetailsVisibility(value: boolean) {
    this.shouldShowLiabilityDetailsSubject$$.next(value);
  }

  updateEirImageLastFetchOn(date: Date | undefined) {
    this.eirImageLastCheckedOn$$.next(date);
  }

  reloadRecoveryCaseData(): void {
    this.reloadVendorRecoveryData$$.next(true);
  }

  updateCurrentStageId(stageId: number): void {
    this.currentStageId$$.next(stageId);
  }

  updateTabSelected(tabIndex: number) {
    this.tabIndex$$.next(tabIndex);
  }

  updateContainerDetails(
    containerDetails: ContainerMoveDto | null | undefined
  ) {
    this.containerDetails$$.next(containerDetails);
  }

  updateRecoverableWorkOrderSelectedLineItems(
    lineItems: { [key: string]: unknown }[]
  ) {
    this.recoverableWorkOrderSelectedLineItems$$.next(lineItems);
  }

  updateWorkOrderLineItems(lineItems: WorkOrderLineItemDto[]) {
    this.workOrderLineItems$$.next(lineItems);
  }

  updateCaseType(vendorRecoveryCase: CustomerRecoveryCaseDto) {
    if (vendorRecoveryCase.createdBy) {
      this.caseType =
        !vendorRecoveryCase.workOrderNumber &&
        !['sys', 'system'].includes(
          vendorRecoveryCase.createdBy.toLocaleLowerCase()
        )
          ? CaseTypeEnum.CaseWithOutWorkOrder
          : CaseTypeEnum.CaseWithWorkOrder;
    } else {
      this.caseType = CaseTypeEnum.CaseWithWorkOrder;
    }
  }

  getWorkFlowStageByIdForVendor(stageId: number): WorkFlowStage {
    let stageName = WorkFlowStage.New;
    switch (stageId) {
      case 1:
        stageName = WorkFlowStage.New;
        break;
      case 3:
        stageName = WorkFlowStage.Liability;
        break;
      case 6:
        stageName = WorkFlowStage.DebitNote;
        break;
      case 5:
        stageName = WorkFlowStage.Complete;
    }
    return stageName as WorkFlowStage;
  }

  getCaseStatusIDByName(status: string): number {
    let caseStatusId = 1;
    switch (status) {
      case RecoveryCaseStatus.New:
        caseStatusId = 1;
        break;
      case RecoveryCaseStatus.DamageEstimation:
        caseStatusId = 2;
        break;
      case RecoveryCaseStatus.Liability:
        caseStatusId = 3;
        break;
      case RecoveryCaseStatus.Invoice:
        caseStatusId = 4;
        break;
      case RecoveryCaseStatus.Complete:
        caseStatusId = 5;
        break;
      case RecoveryCaseStatus.Closed:
        caseStatusId = 6;
        break;
      case RecoveryCaseStatus.AutoClosed:
        caseStatusId = 7;
        break;
    }
    return caseStatusId as number;
  }

  getStageIdName(status: WorkFlowStage): number {
    let caseStatusId = 1;
    switch (status) {
      case WorkFlowStage.New:
        caseStatusId = 1;
        break;
      case WorkFlowStage.Liability:
        caseStatusId = 2;
        break;
      case WorkFlowStage.DebitNote:
        caseStatusId = 3;
        break;
      case WorkFlowStage.Complete:
        caseStatusId = 4;
        break;
    }
    return caseStatusId as number;
  }

  updateWorkOrderDate(date: Date | null | undefined) {
    this.workOrderDateChange$$.next(date);
  }

  caseDocumentGet(
    caseId: number
  ): Observable<ClaimReferenceDocumentDto | null> {
    return this._recoveryClaimService
      .recoveryCasesCaseIdAttachmentsGet(caseId, this.apiVersion)
      .pipe(
        map((response) => {
          if (!response || !response.data) return null;
          return response.data;
        })
      );
  }

  caseDocumentPost(
    imagesAssignedToCases: ClaimReferenceDocumentDto
  ): Observable<ClaimReferenceDocumentDto | null> {
    return this._recoveryClaimService
      .recoveryCasesCaseIdAttachmentsPost(
        imagesAssignedToCases.caseId ?? 0,
        this.apiVersion,
        { claimReferenceDocumentDto: imagesAssignedToCases }
      )
      .pipe(
        map((response) => {
          if (!response || !response.data) return null;
          return response.data;
        })
      );
  }

  caseDocumentDelete(documentId: number): void {
    this._recoveryCaseDocumentService.caseDocumentsIdDelete(
      documentId,
      this.apiVersion
    );
  }

  inspectionsGet(
    _caseNumber: string,
    containerNumber: string | undefined,
    _incidentDate: Date | null,
    workOrderDate: Date | null
  ): Observable<Array<Inspection> | null> {
    let dateToFetchInspections = new Date();
    const toDatePlusVal = environment.searchIntervalForInspections;

    if (workOrderDate && workOrderDate.toString() != '0001-01-01T00:00:00') {
      dateToFetchInspections = new Date(workOrderDate);
    }

    const fromDate = new Date(dateToFetchInspections.getTime());
    fromDate.setUTCDate(fromDate.getUTCDate() - toDatePlusVal);

    const toDate = new Date(dateToFetchInspections.getTime());
    toDate.setUTCDate(toDate.getUTCDate() + toDatePlusVal);

    return this._recoveryInspectionService
      .inspectionsGet(containerNumber, fromDate, toDate, this.apiVersion)
      .pipe(
        map((response) => {
          if (!response || !response.data) return null;
          return response.data;
        })
      );
  }

  saveImageAvailability(
    _inspectionInfo: InspectionDetailsDTO,
    _caseNumber: string
  ): Observable<boolean> {
    return of(true);
  }

  //Extra functions added as required by interface
  reloadLiabilityPartyDetails() {}
  reloadLiabilityLetters() {}
  reloadInvoices() {}
  updateIssueInvoiceVisibility() {}
  updateCustomerData() {}
}
