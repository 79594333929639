/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { AddCaseDocument } from '../../models/addCaseDocument';
import { BooleanResponse } from '../../models/booleanResponse';
import { CaseDocumentDetailsIEnumerableResponse } from '../../models/caseDocumentDetailsIEnumerableResponse';
import { CaseDocumentDetailsResponse } from '../../models/caseDocumentDetailsResponse';
import { CaseDocumentType } from '../../models/caseDocumentType';
import { UpdateCaseDocument } from '../../models/updateCaseDocument';
import { environment } from '../../../../environments/environment';

@Injectable()
export class RecoveryCaseDocumentService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * This API will fetch list of case document based on search filters
   *
   * @param caseDocumentId
   * @param caseId
   * @param documentType
   * @param dateRangeFromDateTime
   * @param dateRangeToDateTime
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public caseDocumentsGet(
    caseDocumentId?: number,
    caseId?: number,
    documentType?: CaseDocumentType,
    dateRangeFromDateTime?: Date,
    dateRangeToDateTime?: Date,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CaseDocumentDetailsIEnumerableResponse>;
  public caseDocumentsGet(
    caseDocumentId?: number,
    caseId?: number,
    documentType?: CaseDocumentType,
    dateRangeFromDateTime?: Date,
    dateRangeToDateTime?: Date,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CaseDocumentDetailsIEnumerableResponse>>;
  public caseDocumentsGet(
    caseDocumentId?: number,
    caseId?: number,
    documentType?: CaseDocumentType,
    dateRangeFromDateTime?: Date,
    dateRangeToDateTime?: Date,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CaseDocumentDetailsIEnumerableResponse>>;
  public caseDocumentsGet(
    caseDocumentId?: number,
    caseId?: number,
    documentType?: CaseDocumentType,
    dateRangeFromDateTime?: Date,
    dateRangeToDateTime?: Date,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams();
    if (caseDocumentId !== undefined && caseDocumentId !== null) {
      queryParameters = queryParameters.set(
        'CaseDocumentId',
        <any>caseDocumentId
      );
    }
    if (caseId !== undefined && caseId !== null) {
      queryParameters = queryParameters.set('CaseId', <any>caseId);
    }
    if (documentType !== undefined && documentType !== null) {
      queryParameters = queryParameters.set('DocumentType', <any>documentType);
    }
    if (dateRangeFromDateTime !== undefined && dateRangeFromDateTime !== null) {
      queryParameters = queryParameters.set(
        'DateRange.FromDateTime',
        <any>dateRangeFromDateTime.toISOString()
      );
    }
    if (dateRangeToDateTime !== undefined && dateRangeToDateTime !== null) {
      queryParameters = queryParameters.set(
        'DateRange.ToDateTime',
        <any>dateRangeToDateTime.toISOString()
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<CaseDocumentDetailsIEnumerableResponse>(
      'get',
      `${this.basePath}/case-documents`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This API will delete case document details
   *
   * @param id Case document ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public caseDocumentsIdDelete(
    id: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public caseDocumentsIdDelete(
    id: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public caseDocumentsIdDelete(
    id: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public caseDocumentsIdDelete(
    id: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling caseDocumentsIdDelete.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<BooleanResponse>(
      'delete',
      `${this.basePath}/case-documents/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This API will fetch case document details
   *
   * @param id Case document ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public caseDocumentsIdGet(
    id: number,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CaseDocumentDetailsResponse>;
  public caseDocumentsIdGet(
    id: number,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CaseDocumentDetailsResponse>>;
  public caseDocumentsIdGet(
    id: number,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CaseDocumentDetailsResponse>>;
  public caseDocumentsIdGet(
    id: number,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling caseDocumentsIdGet.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<CaseDocumentDetailsResponse>(
      'get',
      `${this.basePath}/case-documents/${encodeURIComponent(String(id))}`,
      {
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This API will update case document details
   *
   * @param id Case document ID
   * @param body Updated document details
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public caseDocumentsIdPut(
    id: number,
    body?: UpdateCaseDocument,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<BooleanResponse>;
  public caseDocumentsIdPut(
    id: number,
    body?: UpdateCaseDocument,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<BooleanResponse>>;
  public caseDocumentsIdPut(
    id: number,
    body?: UpdateCaseDocument,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<BooleanResponse>>;
  public caseDocumentsIdPut(
    id: number,
    body?: UpdateCaseDocument,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling caseDocumentsIdPut.'
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<BooleanResponse>(
      'put',
      `${this.basePath}/case-documents/${encodeURIComponent(String(id))}`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * This API will add case document details
   *
   * @param body Attachments to be added
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public caseDocumentsPost(
    body?: Array<AddCaseDocument>,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<CaseDocumentDetailsIEnumerableResponse>;
  public caseDocumentsPost(
    body?: Array<AddCaseDocument>,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<CaseDocumentDetailsIEnumerableResponse>>;
  public caseDocumentsPost(
    body?: Array<AddCaseDocument>,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<CaseDocumentDetailsIEnumerableResponse>>;
  public caseDocumentsPost(
    body?: Array<AddCaseDocument>,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'application/_*+json',
    ];
    headers = headers.set('Content-Type', consumes);

    return this.httpClient.request<CaseDocumentDetailsIEnumerableResponse>(
      'post',
      `${this.basePath}/case-documents`,
      {
        body: body,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
