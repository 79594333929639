import { Routes } from '@angular/router';
import { CustomerRecoveryComponent } from './components/customer-recovery/customer-recovery.component';
import { EmailTemplateEditComponent } from './admin/email-template/email-template-edit.component';
import { CustomerRecoveryOrchestratorComponent } from './components/customer-recovery/customer-recovery-orchestrator/customer-recovery-orchestrator.component';
import { VendorRecoveryOrchestratorComponent } from './components/vendor-recovery/vendor-recovery-orchestrator/vendor-recovery-orchestrator.component';
import { VendorRecoveryComponent } from './components/vendor-recovery/vendor-recovery.component';
import { authenticationGuard } from '@maersk-global/angular-shared-library';
import { UserManagementComponent } from './admin/user/user-management.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'customer-recovery',
    pathMatch: 'full',
  },
  {
    path: 'customer-recovery',
    component: CustomerRecoveryComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'vendor-recovery',
    component: VendorRecoveryComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'customer-recovery/workflow',
    component: CustomerRecoveryOrchestratorComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'vendor-recovery/workflow',
    component: VendorRecoveryOrchestratorComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'admin/email-template/edit',
    pathMatch: 'full',
    component: EmailTemplateEditComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'admin/user-management',
    pathMatch: 'full',
    component: UserManagementComponent,
    canActivate: [authenticationGuard],
  },
  {
    path: 'help-faq',
    loadChildren: () =>
      import('./components/help-faq/help-faq.routes').then(
        (x) => x.HelpFaqRoutes
      ),
    canActivate: [authenticationGuard],
  },
];
