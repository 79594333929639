/**
 * Fleet CC DCRP Recovery API
 * Connected Claims Damaged Container Recovery APIs used for handling recovery data.
 *
 * OpenAPI spec version: 1.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { InspectionIEnumerableResponse } from '../../models/inspectionIEnumerableResponse';
import { environment } from '../../../../environments/environment';

@Injectable()
export class RecoveryInspectionService {
  protected basePath = environment.ccdcrp_api_base_url;
  public defaultHeaders = new HttpHeaders();

  constructor(protected httpClient: HttpClient) {}

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Retrieves inspection details for a container.
   *   This endpoint supports the following HTTP status codes:    - 200 (OK): Inspection details are successfully retrieved.    - 204 (No Content): Inspection details are not available for requested search parameters.    - 400 (Bad Request): The request is invalid or missing required parameters.    - 500 (Internal Server Error): An unexpected error occurred while processing the request.
   * @param equipmentNumber Equipment number for which inspections need to be fetched
   * @param fromDateTime
   * @param toDateTime
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public inspectionsGet(
    equipmentNumber?: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<InspectionIEnumerableResponse>;
  public inspectionsGet(
    equipmentNumber?: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<InspectionIEnumerableResponse>>;
  public inspectionsGet(
    equipmentNumber?: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<InspectionIEnumerableResponse>>;
  public inspectionsGet(
    equipmentNumber?: string,
    fromDateTime?: Date,
    toDateTime?: Date,
    aPIVersion?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams();
    if (equipmentNumber !== undefined && equipmentNumber !== null) {
      queryParameters = queryParameters.set(
        'equipmentNumber',
        <any>equipmentNumber
      );
    }
    if (fromDateTime !== undefined && fromDateTime !== null) {
      queryParameters = queryParameters.set(
        'FromDateTime',
        <any>fromDateTime.toISOString()
      );
    }
    if (toDateTime !== undefined && toDateTime !== null) {
      queryParameters = queryParameters.set(
        'ToDateTime',
        <any>toDateTime.toISOString()
      );
    }

    let headers = this.defaultHeaders;
    if (aPIVersion !== undefined && aPIVersion !== null) {
      headers = headers.set('API-Version', String(aPIVersion));
    }

    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'text/plain',
      'application/json',
      'text/json',
    ];
    headers = headers.set('Accept', httpHeaderAccepts);

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<InspectionIEnumerableResponse>(
      'get',
      `${this.basePath}/inspections`,
      {
        params: queryParameters,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
