import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
  ALIGN,
  GridCellData,
  GridColumnSchema,
  GridComponent,
  GridRowData,
} from '@maersk-global/angular-shared-library';
import { FooterRowData } from '@maersk-global/angular-shared-library/lib/models/footer-row-data';
import { FooterSchema } from '@maersk-global/angular-shared-library/lib/models/footer-schema';
import { TemplateModel } from '@maersk-global/angular-shared-library/lib/models/template-model';
import { VendorRecoveryOrchestratorComponent } from '../../vendor-recovery/vendor-recovery-orchestrator/vendor-recovery-orchestrator.component';
import { SharedVendorRecoveryCaseService } from '../../vendor-recovery/shared-vendor-recovery-case.service';
import { Observable, combineLatest, map, tap } from 'rxjs';
import { SharedRecoveryCaseService } from '../../../shared-recovery-case-service';
import { CustomerRecoveryCaseDto } from '../../../common/models/customerRecoveryCaseDto';
import { VendorRecoveryCase } from '../../../common/models/vendorRecoveryCase';
import { WorkOrderLineItemDto } from '../../../common/models/workOrderLineItemDto';
import { SharedDataService } from '../../../shared-data-service';

@Component({
  selector: 'vendor-recoverable-cost',
  standalone: true,
  imports: [CommonModule, GridComponent],
  templateUrl: './vendor-recoverable-cost.component.html',
  styleUrl: './vendor-recoverable-cost.component.scss',
})
export class VendorRecoverableCostComponent {
  @Input({ required: true }) item!: TemplateModel;
  items: TemplateModel[] = [];
  costData: GridRowData[] = [];
  gridSchema: GridColumnSchema[] = [];
  costFooter: FooterRowData | null = null;
  footerSchema: FooterSchema[] = [];
  currencyCode = 'USD';
  vendorData?: VendorRecoveryCase;
  exchangeRate = 1.0;
  constructor(
    private _sharedVendorCaseService: SharedVendorRecoveryCaseService,
    private _sharedDataService: SharedDataService
  ) {}

  vendorData$ = this._sharedVendorCaseService.vendorRecoveryCaseData$.pipe(
    tap((vendorData) => {
      this.vendorData = vendorData;
      this.bindSchema();
    })
  );

  gridDataValue$: Observable<boolean> = combineLatest([
    this._sharedVendorCaseService.vendorRecoverableWorkOrderLineItem$,
    this._sharedVendorCaseService.vendorWorkOrderLineItems$,
  ]).pipe(
    map(([selectedWorkOrderLineItem, AllLineItems]) => {
      this.bindGridData(selectedWorkOrderLineItem, AllLineItems);
      return true;
    })
  );

  bindSchema() {
    //Binding grid schema
    if (this.item && this.item.items && this.item.items[0].items) {
      this.gridSchema = this.item.items[0].items.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          displayName: y.label,
          align: y.valueType === 'numeric' ? ALIGN.RIGHT : ALIGN.LEFT,
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
        } as GridColumnSchema;
        return column;
      });
    }
    //Binding footer schema
    if (this.item && this.item.items && this.item.items[1].items) {
      this.footerSchema = this.item.items[1].items.map((y: TemplateModel) => {
        const column = {
          column: y.name,
          align: y.valueType === 'numeric' ? ALIGN.RIGHT : ALIGN.LEFT,
          sequence: y.sequence,
          columnType: y.valueType?.toUpperCase(),
          colSpan: y.colspan,
        } as FooterSchema;
        return column;
      });
    }
  }

  getCost(
    selectedLineItem: { [key: string]: unknown }[],
    lineItems: WorkOrderLineItemDto[]
  ) {
    let cost = {
      recoverableCostUSD: 0,
      recoverableCostLocal: 0,
      woCostIncludingTaxUSD: 0,
      woCostIncludingTaxLocal: 0,
      totalCostUSD: 0,
      totalCostLocal: 0,
    };
    lineItems.forEach((i) => {
      cost.woCostIncludingTaxUSD += i.repairTotalCostUSD ?? 0;
      cost.woCostIncludingTaxLocal += i.repairTotalCostLocalCurrency ?? 0;
    });

    selectedLineItem.forEach((i) => {
      cost.recoverableCostUSD +=
        ((i['repairTotalCostUSD'] as GridCellData).value as number) ?? 0;
      cost.recoverableCostLocal +=
        ((i['repairTotalCostLocalCurrency'] as GridCellData).value as number) ??
        0;
    });

    cost.recoverableCostLocal = this._sharedDataService.formatDecimal(
      cost.recoverableCostLocal
    );
    cost.recoverableCostUSD = this._sharedDataService.formatDecimal(
      cost.recoverableCostUSD
    );
    cost.woCostIncludingTaxLocal = this._sharedDataService.formatDecimal(
      cost.woCostIncludingTaxLocal
    );
    cost.woCostIncludingTaxUSD = this._sharedDataService.formatDecimal(
      cost.woCostIncludingTaxUSD
    );
    cost.totalCostLocal = this._sharedDataService.formatDecimal(
      cost.recoverableCostLocal + cost.woCostIncludingTaxLocal
    );
    cost.totalCostUSD = this._sharedDataService.formatDecimal(
      cost.recoverableCostUSD
    );
    return cost;
  }

  bindGridData(
    selectedLineItem: { [key: string]: unknown }[],
    lineItems: WorkOrderLineItemDto[]
  ) {
    // Filter items which are required for grid data and footer data
    this.items = this.item.items?.filter((o) => o.sequence) as TemplateModel[];

    const cost = this.getCost(selectedLineItem, lineItems);

    // Populate grid data excluding aboveCoverageCostUSD
    this.costData = this.items
      .filter((o) => o.name !== 'aboveCoverageCostUSD')
      .map((item: TemplateModel) => ({
        row: {
          costType: { value: item.label },
          costLocal: {
            value: this.getGridValueBasedOnLocal(item.name, cost),
          },
          costUSD: { value: this.getGridValueBasedOnUSD(item.name, cost) },
        },
        isMasterRow: false,
        showChildRowData: false,
      })) as GridRowData[];

    // Populate footer data for aboveCoverageCostUSD
    const aboveCoverageItem = this.items.find(
      (o) => o.name === 'aboveCoverageCostUSD'
    );
    if (aboveCoverageItem) {
      this.costFooter = {
        row: {
          costType: { value: aboveCoverageItem.label },
          costLocal: {
            value: cost.totalCostLocal,
          },
          costUSD: { value: cost.totalCostUSD },
        },
      } as FooterRowData;
    }
  }

  getGridValueBasedOnLocal(property: string, cost: any) {
    let localCost = 0;
    switch (property) {
      case 'woCostUSD':
        localCost = cost.woCostIncludingTaxLocal;
        break;
      case 'recoverableCostUSD':
        localCost = cost.recoverableCostLocal;
        break;
      case 'handlingDamageCostUSD':
        localCost = 0;
        break;
    }
    return localCost;
  }
  getGridValueBasedOnUSD(property: string, cost: any) {
    let localCost = 0;
    switch (property) {
      case 'woCostUSD':
        localCost = cost.woCostIncludingTaxUSD;
        break;
      case 'recoverableCostUSD':
        localCost = cost.recoverableCostUSD;
        break;
      case 'handlingDamageCostUSD':
        localCost = 0;
        break;
    }
    return localCost;
  }

  private formatDecimal(value: number): number {
    return Math.round(value * 100) / 100;
  }
}
