{
  "items": [
    {
      "name": "userId",
      "label": "User ID",
      "valueType": "string",
      "hidden": true,
      "sequence": 0
    },
    {
      "name": "firstName",
      "label": "First Name",
      "valueType": "string",
      "hidden": true,
      "sequence": 1
    },
    {
      "name": "lastName",
      "label": "Last Name",
      "valueType": "string",
      "hidden": true,
      "sequence": 2
    },
    {
      "name": "name",
      "label": "Name",
      "valueType": "string",
      "isClickable": true,
      "columClickType": "LINK_CLICK",
      "sequence": 3
    },
    {
      "name": "loginId",
      "label": "Login ID",
      "valueType": "string",
      "sequence": 4
    },
    {
      "name": "emailId",
      "label": "Email ID",
      "valueType": "string",
      "sequence": 5
    },
    {
      "name": "roleName",
      "label": "Role Name",
      "valueType": "string",
      "sequence": 6
    },
    {
      "name": "isExternal",
      "label": "External User",
      "valueType": "string",
      "sequence": 7
    },
    {
      "name": "isActive",
      "label": "Status",
      "valueType": "string",
      "sequence": 8
    }
  ]
}
